// @import "./swiper.scss";
// @import "../public/fonts/abcfavorit/stylesheet.css";

html,
body {
  padding: 0;
  margin: 0;
  font-family: "ABC Favorit", "Roboto", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.dangerously {
  p {
    margin: 0;
  }
}

.mui-image-wrapper {
  width: fit-content !important;
}

/* Random page */

.random-image-idle {
  height: 300px;
  width: 10px;
  object-fit: cover;
}

.sketch-picker {
  padding: 20px 20px 10px !important;
}

.rdw-editor-main {
  padding: 0 10px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
}
